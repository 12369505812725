import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/kenneth/Repos/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.meetup.com/typebrigade/events/222545955/"
      }}>{`Type Brigade №26`}</a>{` is June 4th, making it one year since we relaunched Vancouver’s typography meetup as `}<a parentName="p" {...{
        "href": "introducing-type-brigade"
      }}>{`Type Brigade`}</a>{`. I’m very excited to welcome one Type Brigade regular and one newcomer to present at our next event: Travis Gertz & Ivana McConnell!`}</p>
    <figure {...{
      "className": "figure--breakout"
    }}>
      <span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e703feba95de44b6b1eac4a07e6bb37e/56e36/blog-announcing-type-brigade-26-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAedJREFUeNpd1MtLlGEUx/FXUQi0EFyJCIqbIPJKhLgoNAQ1EQIXmpHX0lRaJClYWCoIunGjKOYFNazIhZioKV7xlpaXRvOCSojVn9H34E94nYEP78z7PnOe85xzZhzHcXyc81cwclGEOlTjhp75eF2T8AavUYGnuOZ4vWxBE6LQjC9oR5Ce++kag48YxksU4iseuoPdxho2UYIW7GNHwS+CXUG37u8ogQ6tncJ1WxSAd/iOXaTiLrbxDbPIVEAryRZ+YgkheIUjzKDSFiViQgH/oADxONQG0wp0FZ3w4BTj2sTq9xcLeG43olUv2+VEn1Owp2zGkINIDGADB/iMcNxRLbMRZgF91TXrWIJ2fYtf+KHN4nATH3Rcj0rxSZva+3zXFFxqjo3LPJbVySQ9S1AAy+4Mg3iMfzqZZZ52kWE6etSEETQiC7HaxILdwnvXkbvUwEPV32r9zAImq8BbKuyGjjSHVazghWo4qHWWUa8y+q2JsO+WWcBSfThQ3Y61q0f3FvEEgZo5j47cj/uaDGvgOvIsYIM6OSkrKvy6sll01TFDx7OJ6EOxErD1o4hw1MEq1c2O1oYhNcSG9ZGC+epapVLYTNaqduaeu7sPEKovRaq4rSqH4/XHYD/DctSjRj9Nm0F/e/gfwQyH1IDMvewAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Alanna Munro’s clever event numerals, custom designed for Type Brigade",
            "title": "Alanna Munro’s clever event numerals, custom designed for Type Brigade",
            "src": "/static/e703feba95de44b6b1eac4a07e6bb37e/29007/blog-announcing-type-brigade-26-1.png",
            "srcSet": ["/static/e703feba95de44b6b1eac4a07e6bb37e/e17e5/blog-announcing-type-brigade-26-1.png 400w", "/static/e703feba95de44b6b1eac4a07e6bb37e/5a190/blog-announcing-type-brigade-26-1.png 800w", "/static/e703feba95de44b6b1eac4a07e6bb37e/29007/blog-announcing-type-brigade-26-1.png 1600w", "/static/e703feba95de44b6b1eac4a07e6bb37e/56e36/blog-announcing-type-brigade-26-1.png 1750w"],
            "sizes": "(max-width: 1600px) 100vw, 1600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>
      <figcaption parentName="figure">{`
The Type Brigade №26 event numerals, designed by [Alanna Munro](https://twitter.com/alannamun).
`}</figcaption>
    </figure>
    <h2>{`Our speakers`}</h2>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/travisgertz"
      }}>{`Travis Gertz`}</a>{` is the co-founder of `}<a parentName="p" {...{
        "href": "http://louderthanten.com/"
      }}>{`Louder Than Ten`}</a>{`, the school for freelancers and teams managing design projects. He has a knack for translating what’s great about traditional publication designs to a more appropriate format on the web. At Type Brigade №26, he’ll show us a new kind of breakpoint.`}</p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/ivanamcconnell"
      }}>{`Ivana McConnell`}</a>{` is an interaction designer in Vancouver, making things at `}<a parentName="p" {...{
        "href": "https://www.myplanet.com/"
      }}>{`Myplanet`}</a>{`. She’ll show us the larger cultural impact typographic decisions can create—flashpoints, where typography was more powerful than we gave it credit for.`}</p>
    <h2>{`Our sponsors`}</h2>
    <p>{`Our good friends at `}<a parentName="p" {...{
        "href": "http://mobify.com/careers"
      }}>{`Mobify`}</a>{` and `}<a parentName="p" {...{
        "href": "http://portpaperco.com"
      }}>{`Port Paper Co.`}</a>{` help make Type Brigade possible by sponsoring space for the event, and our gorgeous letterpress prints, respectively. We like to provide drinks for attendees as well—if you’re interested in sponsoring this Type Brigade or a future event, `}<a parentName="p" {...{
        "href": "mailto:kenneth@typebrigade.com?subject=Sponsorships"
      }}>{`please get in touch`}</a>{`.`}</p>
    <h2>{`Our Code of Conduct`}</h2>
    <p>{`We have a `}<a parentName="p" {...{
        "href": "/code-of-conduct"
      }}>{`Code of Conduct`}</a>{`: it’s our commitment to providing a safe and harassment free environment for attendees of all backgrounds. We make a conscious effort to welcome people of all backgrounds and experiences to attend and present at Type Brigade. We know we won’t get everything completely right, but we are always trying to make the community and event better. If you have any concerns, please don’t hesitate to contact me at `}<a parentName="p" {...{
        "href": "mailto:kenneth@typebrigade.com"
      }}>{`kenneth@typebrigade.com`}</a>{`, or Alanna Munro at `}<a parentName="p" {...{
        "href": "mailto:alanna@typebrigade.com"
      }}>{`alanna@typebrigade.com`}</a>{`.`}</p>
    <h2>{`Our custom numeral`}</h2>
    <p>{`Another thing important to us are our custom event numerals. Every Type Brigade is represented by a new piece of lettering. Our number 26 was designed by the talented `}<a parentName="p" {...{
        "href": "http://twitter.com/alannamun"
      }}>{`Alanna Munro`}</a>{`, who’s currently available for freelance lettering work and runs `}<a parentName="p" {...{
        "href": "http://locallettering.com"
      }}>{`LocalLettering.com`}</a>{`.`}</p>
    <h2>{`Join the waitlist`}</h2>
    <p>{`A waitlist is available for the next Type Brigade, and we will open more spots closer to the event. `}<a parentName="p" {...{
        "href": "https://twitter.com/typebrigade"
      }}>{`Follow us on Twitter`}</a>{` for more updates.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      